
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: { },
})
export default class Scope1ActivityRecorderDetail extends Vue {

    @Prop({
        default: () => new OM.ActivityDataRecorder()
    }) activityData: OM.ActivityDataRecorder;

    created() {}

}

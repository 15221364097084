
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient } from '@/services/Services';
import ActivityDataDetailModal from '../modals/activityDataDetailModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';

@Options({
    components: { },
})
export default class DataInventory extends Vue {

    datas: OM.ActivityDataRecorderListVM[] = [];

    created() {
        this.init();
    }

    init() {
        ActivityDataRecorderClient.getAllByCompany()
        .then(x => {
            this.datas = x;
        })
    }

    openDetail(itemId: string) {
        ActivityDataRecorderClient.getById(itemId)
        .then(x => {
            this.$opModal.show(ActivityDataDetailModal, {
                activityData: x
            })
        })
    }

    deleteActivity(itemId: string) {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("Attention", "Attention"),
            subtitle: this.$localizationService.getLocalizedValue("Are you sure you want to delete this item?", "Are you sure you want to delete this item?"),
            confirm: this.$localizationService.getLocalizedValue("Delete item", "Delete item"),
            confirmCb: () => {
                ActivityDataRecorderClient.deleteById(itemId)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            },
            deny: this.$localizationService.getLocalizedValue("Avoid", "Avoid"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

}


import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import Scope1ActivityRecorderDetail from '../components/scope1ActivityRecorderDetail.vue';
import Scope2ActivityRecorderDetail from '../components/scope2ActivityRecorderDetail.vue';
import Scope3ActivityRecorderDetail from '../components/scope3ActivityRecorderDetail.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        Scope1ActivityRecorderDetail,
        Scope2ActivityRecorderDetail,
        Scope3ActivityRecorderDetail
    }
})
export default class ActivityDataDetailModal extends Vue {

    @Prop({
        default: () => new OM.ActivityDataRecorder()
    }) activityData: OM.ActivityDataRecorder;

    viewModel = VM;

    created() {}
    
}

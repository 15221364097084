
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: { },
})
export default class Scope3ActivityRecorderDetail extends Vue {

    @Prop({
        default: () => new OM.ActivityDataRecorder()
    }) activityData: OM.ActivityDataRecorder;

    created() {}

    get isTransportationSelected() {
        return this.activityData.emissionDataBase.category == 'Upstream transportation' || this.activityData.emissionDataBase.category == 'Downstream transportation';
    }
    get isTravelSelected() {
        return this.activityData.emissionDataBase.category == 'Business travel';
    }
    get isFuelAndEnergySelected() {
        return this.activityData.emissionDataBase.category == 'Fuel and energy related activities';
    }

    get isByAirFlights() {
        return (<OM.Scope3EmissionData>this.activityData.emissionDataBase).journeyMode == 'By air flights';
    }
    get isBySeaFerry() {
        return (<OM.Scope3EmissionData>this.activityData.emissionDataBase).journeyMode == 'By sea ferry';
    }

}
